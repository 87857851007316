.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-section {
  /* color: #BDF5FF; */
  height: 70vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.transform-section {
  height: 70vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.navmenu {
  color: #BDF5FF !important;
  background: none !important;
  background-color: none !important;
}
.navbar.transparent.navbar-inverse .navbar-inner {
  background: rgba(0,0,0,0.4);
}
.navbar-brand, .nav-link {
  color: #BDF5FF !important;
}

.spelling {
  color: white !important;
}

.servicecard {
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  color: white !important;
  margin-bottom: 50px;
}

.gradient-text {
  font-size: 5rem !important;
  background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(255, 253, 252, 0.236) 99%,rgba(255,253,252,0.12) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px gray;
  
}

.white-text {
  color: white !important;
  font-size: 1.2rem !important;
}



/* moble*/
.services-row {
  justify-content: space-between;
}

.artificial-intelligence, .globe {
  width: 50% !important;
}

/* phone */
@media all and (min-width:20px) and (max-width: 480px) {
  .services-row {
    justify-content: center;
  }
  .hero-section, .transform-section {
    height: 100vh !important;
  }

  .transform-section {
    /* margin-bottom: 50px !important; */
  }

  .gradient-text {
    -webkit-text-stroke: 0px !important;
  }

  .artificial-intelligence, .globe {
    width: 100% !important;
  }
}

.companybrands {
  max-width: 200px !important;
}

.techcompany {
  margin: 120px auto;
}

.calendlybutton button {
  padding: 5px;
  border: none;
  border-radius: 5px;
  background-color: white;
}

ul li {
  list-style: none;
  text-align: left;
}

.artificial-intelligence, .footer {
  color: whitesmoke;
  margin: 140px auto;
}

.artificial-intelligence h1 {
   background: linear-gradient(to bottom, rgba(247,247,247,1) 0%,rgba(255, 253, 252, 0.236) 99%,rgba(255,253,252,0.12) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px gray;
}